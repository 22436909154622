/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        // var winH = $(window).outerHeight();
        // var headerH = $('header').height();
        var winW = $(window).outerWidth();
        // var mobileWidth = 768;


        // HERO
        // $('.hero-gallery').carousel({
        //   interval: 3800
        // });

       // Hero Slider
       var $heroSlider = $('.hero-slider'),
       $heroSlides = $('.hero-slider .hero-slide');

       if ($heroSlides.length > 1) {
         $heroSlider.slick({
           accessibility: true,
           arrows: false,
           dots: false,
           fade: true,
           slidesToShow: 1,
           slidesToScroll: 1,
           pauseOnFocus: false,
           pauseOnHover: false,
           pauseOnDotsHover: false,
           speed: 1000,
           infinite: true,
           draggable: false,
           swipe: false,
           autoplay: true,
           autoplaySpeed: 3750,
           lazyLoad: 'progressive',
         });
       }



        // MENU
        //-- Do the menu
        $('.nav-icon').on( 'click', function(){
          $('.nav-icon').toggleClass('open');
          $('#nav-icon').toggleClass('open');
          $('nav').toggleClass('open');

          var scrollPosition = $(window).scrollTop();
          console.log(scrollPosition);

          // Show brand if nav has class open
          if( $('nav').hasClass('open') ){
              $('.brand').removeClass('show');
              $('body').css({ overflowY: 'hidden' });
              $('header').removeClass('scrolled');
          } else {
            $('body').css({ overflowY: 'auto' });
            if(scrollPosition > 0) {
              $('.brand').addClass('show');
              $('header').addClass('scrolled');
            } else {
              $('.brand').removeClass('show');
              // Check for single post
              if($('body').hasClass('single')) {
                $('header').addClass('scrolled');
              } else {
                $('header').removeClass('scrolled');
              }

            }
          }

        });



        // Other Nav

        //-- Tab show/hide nav
        // New show hide content to replace nav pills
        // $('#showall').on('click',function() {
        //   $('.tab-target').show();
        // });

        function tabLoading() {
          $(document).ready(function(){
            $('.tab-target').hide().removeClass('active');
            $('#div0').show().addClass('active');
            $('.tab-link[target=0]').addClass('active');
          });
          $('.tab-link').on('click',function() {
            // console.log('clicky');
            $('.tab-target').fadeOut().removeClass('active');
            $('.tab-link').removeClass('active');
            $('#div' + $(this).attr('target')).fadeIn().addClass('active');
            $('.tab-link[target=' + $(this).attr('target')+']').addClass('active');

            // $(".tab-slider").on("afterChange", function (event, slick, currentSlide){
            //   $('.tab-target').fadeOut().removeClass('active');
            //   $('.tab-link').removeClass('active');
            //   $('#div' + $(this).attr('target')).fadeIn().addClass('active');
            //   $('.tab-link[target=' + $(this).attr('target')+']').addClass('active');
            // });
          });
        }
        tabLoading();






        // var scrollPosition = $(window).scrollTop();
        // $(window).on( 'scroll', function() {
        //   var scrollPosition = $(window).scrollTop();
        //   console.log(scrollPosition);
        // });
        // console.log(scrollPosition);


        // Scroll functions
        if($('body').hasClass('home')) {

          $(window).on( 'scroll', function() {
            if($(window).scrollTop() > 100) {
              $('.brand').addClass('show');
              $('header').addClass('scrolled');
            }
            if($(window).scrollTop() < 100) {
              $('.brand').removeClass('show');
              $('header').removeClass('scrolled');
            }
          });

        }

        if($('body').hasClass('single')) {
          $('header').addClass('scrolled');
        } else {
          $(window).on( 'scroll', function() {
            if($(window).scrollTop() > 100) {
              $('header').addClass('scrolled');
              $('.brand').addClass('show');
              $('.brand.page').addClass('show');
            }
            if($(window).scrollTop() < 100) {
              $('header').removeClass('scrolled');
              $('.brand').removeClass('show');
              $('.brand.page').removeClass('show');
            }
          });
        }

        // Scroll to top
        //-- scroll
        $('.scrollToTop').css('display', 'none');
        $(window).on( 'scroll', function(e) {
          e.preventDefault();
          if ($(this).scrollTop() > 100) {
            $('.scrollToTop').fadeIn();
          } else {
            $('.scrollToTop').fadeOut();
          }
        });
        //-- click
        $('.scrollToTop').on( 'click', function(){
          $("html, body").animate({ scrollTop: 0 }, 1200);
        });




        // IMAGES
        function post_slider_image() {
          if(winW <= 1025) {
          $(document).on('ready', function(){
              $('.post-slider img').each(function(){
                var postSliderImageW = $('.post-slider .slide > img').width();
                $('.post-slider .slide > img').css({ height : postSliderImageW });
              });
            });
          }
        }
        post_slider_image();


        //-- Let's make the galleries slick sliders
        $('.tab-slider').slick({
          setPosition : 0,
          autoplay: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1200,
          dots: false,
          lazyLoad: 'progressive',
          prevArrow: '<div class="slick-button slick-prev"></div>',
          nextArrow: '<div class="slick-button slick-next"></div>',
        });

        $('.tab-slider').slick("setPosition", 0);
        $('.tab-slider').slick('refresh');
        
        
        $('.tab-slider').each(function(){
          $('.tab-link').on('click', function(e){
            e.preventDefault();

            $('.tab-slider').fadeIn('400', function() {
                $('.tab-slider').slick('refresh');
                $('.tab-slider').slick('setPosition', 0);
            });
          });
        });



        $('.post-slider').slick({
          autoplay: true,
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          speed: 1200,
          dots: false,
          prevArrow: '<div class="slick-button slick-prev"></div>',
          nextArrow: '<div class="slick-button slick-next"></div>',
          responsive: [
            // {
            //   breakpoint: 1440,
            //   settings: {
            //     slidesToShow: 4,
            //     slidesToScroll: 4,
            //   }
            // },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
        });





        // GRAPHICS
        // -- Change images with svg class to svg
        jQuery('img.svg').each(function(){
          var $img = jQuery(this);
          var imgID = $img.attr('id');
          var imgClass = $img.attr('class');
          var imgURL = $img.attr('src');
              jQuery.get(imgURL, function(data) {
                  // Get the SVG tag, ignore the rest
                  var $svg = jQuery(data).find('svg');
                  // Add replaced image's ID to the new SVG
                  if(typeof imgID !== 'undefined') {
                      $svg = $svg.attr('id', imgID);
                  }
                  // Add replaced image's classes to the new SVG
                  if(typeof imgClass !== 'undefined') {
                      $svg = $svg.attr('class', imgClass+' replaced-svg');
                  }
                  // Remove any invalid XML tags as per http://validator.w3.org
                  $svg = $svg.removeAttr('xmlns:a');
                  // Replace image with new SVG
                  $img.replaceWith($svg);
              }, 'xml');
        });


        // Refresh values on resize or rotation
        var _refresh = function () {

          // tabLoading();

          post_slider_image();

        };
        $(window).on('resize', _refresh);
        $(document).on('ready', _refresh);
        $(window).on('load', _refresh);



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


        function landfillLoading() {
          $(document).ready(function(){
            $('.landfill .tab-target').hide().removeClass('active');
            $('.landfill #div0').show().addClass('active');
            $('.landfill .tab-link[target=0]').addClass('active');
          });
          $('.landfill .tab-link').on('click',function() {
            $('.landfill .tab-target').hide().removeClass('active');
            $('.landfill .tab-link').removeClass('active');
            $('.landfill #div' + $(this).attr('target')).show().addClass('active');
            $('.landfill .tab-link[target=' + $(this).attr('target')+']').addClass('active');
          });
        }
        landfillLoading();


        // Lets do some number counting
        //-- Find the landfill panel with Waypoint
        $('.landfill').waypoint(function() {
          // animation code
            // console.log($('.counting').data('value'));
            $('#div0 .counting.count-first').prop('Counter',0).animate({
                Counter: $('.counting').data('value')
            }, {
                duration: 1500,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
                }
            });
        }, { offset: '85%', triggerOnce: true });

        // On click, let's count the number on that div
        $('.landfill .tab-link').on('click', function(e){
          e.preventDefault();
          var tabIndex = $(this).data('target');
          console.log(tabIndex);
          $('#div'+tabIndex+' .counting').prop('Counter',0).animate({
              Counter: $('#div'+tabIndex+' .counting').data('value')
          }, {
              duration: 900,
              easing: 'swing',
              step: function (now) {
                  $(this).text(Math.ceil(now).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
              }
          });
        });

        // When its the slick slider, do a different check on click
        // On click, let's count the number on that div
        $('body').on('click', '.landfill .slick-button', function () {

          $('.landfill .slick-slide').removeClass('active');
          $('.landfill .tab-target').hide().removeClass('active');

          // $(".landfill .tab-buttons").on("beforeChange", function (event, slick, currentSlide){
          //   $('.landfill .tab-target').hide().removeClass('active');
          // });
          $(".landfill .tab-buttons").on("afterChange", function (event, slick, currentSlide){

            var tabIndex = $('.landfill .tab-link.slick-current.slick-active').data('target');
            // console.log(tabIndex);

            $('.landfill .tab-target').hide().removeClass('active');
            $('.landfill .tab-link').removeClass('active');
            $('.landfill #div' + tabIndex + '').show().addClass('active');
            $('.tab-link[target=' + tabIndex +']').addClass('active');

              // console.log($('.counting').text());
              $('#div'+tabIndex+' .counting').prop('Counter',0).animate({
                  Counter: $('#div'+tabIndex+' .counting').data('value')
              }, {
                  duration: 900,
                  easing: 'swing',
                  step: function (now) {
                      $(this).text(Math.ceil(now).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
                  }
            });

          });
        });


        // Hero Gallery slider
        // $(".hero-gallery.carousel > .carousel-inner > .carousel-item:first").addClass("active");
        // $('.hero-gallery.carousel').carousel({
        //   interval: 1000,
        //   pause: false
        // });



        // Supporters Slider
        var $supporterSlider = $('.supporters-slider'),
            $supporterSlides = $('.supporters-slider .supporter-slide');

            if ($supporterSlides.length > 1) {
              $supporterSlider.slick({
                accessibility: true,
                prevArrow: '<div class="slick-button slick-prev"></div>',
                nextArrow: '<div class="slick-button slick-next"></div>',
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                pauseOnFocus: false,
                pauseOnHover: false,
                pauseOnDotsHover: false,
                speed: 2200,
                infinite: true,
                draggable: true,
                swipe: true,
                autoplay: true,
                autoplaySpeed: 6000,
                responsive: [
                  // {
                  //   breakpoint: 992,
                  //   settings: 'unslick'
                  // },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    }
                  },
                  {
                    breakpoint: 576,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    }
                  }
                ]
              });
            }


        // var winW = $(window).outerWidth();
        // var mobileWidth = 1023;


        // Landfill Slider
        var $landfillSlider = $('.landfill .tab-buttons'),
        $landfillSlides = $('.landfill .tab-buttons .tab-link');

        if ($landfillSlides.length > 1) {
          $landfillSlider.slick({
            // centerMode: true,
            // centerPadding: '60px',
            accessibility: true,
            prevArrow: '<div class="slick-button slick-prev"></div>',
            nextArrow: '<div class="slick-button slick-next"></div>',
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnFocus: false,
            pauseOnHover: false,
            pauseOnDotsHover: false,
            speed: 1500,
            infinite: true,
            draggable: true,
            swipe: true,
            autoplay: false,
            autoplaySpeed: 6000,
            mobileFirst: true,
            responsive: [
              {
                breakpoint: 992,
                settings: 'unslick'
              },
              {
                breakpoint: 556,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
              },
            ]
          });
        }




        var imageW = $('figure.wp-block-media-text__media').width();
        $('figure.wp-block-media-text__media, figure.wp-block-media-text__media img').css({ height : imageW - 75 });
        
        $('figure.wp-block-media-text__media img[src$=".gif"]').addClass('gif');

        if($('figure.wp-block-media-text__media img').hasClass('gif')) {
          $('figure.wp-block-media-text__media img[src$=".gif"]').parent().addClass('gif');
        }
        //   console.log('giffy');
        //   $('figure.wp-block-media-text__media, figure.wp-block-media-text__media img[src$=".gif"]').css({ height : 'auto' });
        // } else {





        var figParent = $('.wp-block-column figure').parent().attr('class');
        // console.log(figParent);
        if(figParent) {
          $('.wp-block-column figure').parent().addClass('no-padding');
          $('.wp-block-column figure.wp-block-media-text__media, .wp-block-column figure.wp-block-image').parent().addClass('image-column');
          $('.wp-block-column figure.wp-block-video').parent().addClass('video-column');
        }



        // Refresh values on resize or rotation
        var _refresh = function () {

          // $('.landfill .nav.nav-pills').slick('resize');
          // post_slider_image();

        };
        $(window).on('resize', _refresh);
        $(document).on('ready', _refresh);
        $(window).on('load', _refresh);




        // GRAPHICS
        // -- Change images with svg class to svg
        jQuery('img.svg').each(function(){
          var $img = jQuery(this);
          var imgID = $img.attr('id');
          var imgClass = $img.attr('class');
          var imgURL = $img.attr('src');
              jQuery.get(imgURL, function(data) {
                  // Get the SVG tag, ignore the rest
                  var $svg = jQuery(data).find('svg');
                  // Add replaced image's ID to the new SVG
                  if(typeof imgID !== 'undefined') {
                      $svg = $svg.attr('id', imgID);
                  }
                  // Add replaced image's classes to the new SVG
                  if(typeof imgClass !== 'undefined') {
                      $svg = $svg.attr('class', imgClass+' replaced-svg');
                  }
                  // Remove any invalid XML tags as per http://validator.w3.org
                  $svg = $svg.removeAttr('xmlns:a');
                  // Replace image with new SVG
                  $img.replaceWith($svg);
              }, 'xml');
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
